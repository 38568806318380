import React from "react";
import Page from "../components/Layout/Page";
import { graphql } from "gatsby";
import MarkdownFileCard from "../components/Elements/MarkdownFileCard";
import pageColors from "../data/pageColors";
import pageTitles from "../data/pageTitles";
import PageHeader from "../components/Elements/PageHeader";

const Stories = ({ data: { allMarkdownRemark: stories } }) => (
    <Page
        title={pageTitles.stories}
        layoutClassName={`bg-${pageColors.stories}-800 text-white`}
    >
        <PageHeader
            color={pageColors.stories}
            counts={[`${stories.totalCount} stories`]}
        >
            Before I took up any of my other hobbies, the first thing I did was
            write when I was a kid, a lot, on the old typewriter and in
            notebooks. I wrote a lot of short (and medium) stories throughout my
            youth, so a lot of those have probably aged but here are they
            nontheless.
        </PageHeader>
        {stories.nodes.map((story) => (
            <MarkdownFileCard
                key={story.id}
                to={story.fields.slug}
                blogpost={story}
                className={`dark-hover:bg-${pageColors.stories}-800 dark:bg-${pageColors.stories}-900`}
                titleColor={`text-${pageColors.stories}-600 dark:text-white`}
            />
        ))}
    </Page>
);

export const query = graphql`
    {
        allMarkdownRemark(
            filter: {
                fields: { type: { eq: "stories" } }
                frontmatter: { status: { eq: "Done" } }
            }
            sort: { fields: frontmatter___date, order: DESC }
        ) {
            totalCount
            nodes {
                id
                excerpt(truncate: false, pruneLength: 400)
                timeToRead
                fields {
                    type
                    slug
                }
                frontmatter {
                    title
                    lang
                    date(fromNow: true)
                    cover {
                        childImageSharp {
                            fluid(quality: 90) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default Stories;
